import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshop,
  InternalInstructor,
  InternalTestimonial,
  InternalWorkshopTab
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import styles from './ciranda-de-mulheres.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Ciranda de Mulheres' />
      <InternalHeader withType>
        <small>Online ou Presencial</small>
        <h1>
          Ciranda de
          <br />
          <strong>Mulheres</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre a <strong>Ciranda</strong>
            </h2>
            <p>Olá querida, estava esperando por você. Que bom que você veio, seja bem-vinda!</p>
            <p>
              A Ciranda é um grupo para Mulheres que buscam desenvolvimento pessoal ou profissional. Nos nossos
              encontros trataremos de temas atuais, dificuldades compartilhadas por nós mulheres, mas também falaremos
              de sororidade, o sentimento de solidariedade e empatia entre as mulheres que une o poder da nossa palavra,
              dos nossos atributos e nossa força criadora.
            </p>
            <h2>
              O que vamos <strong>vivenciar</strong>
            </h2>
            <p>
              Através dos contos do livro Mulheres que Correm com os Lobos, vamos vivenciar um mergulho na nossa
              natureza profunda, na força feminina ancestral da Mulher selvagem. Sempre que duas ou mais mulheres, duas
              ou mais Almas se encontram para Cirandar forma-se ali um refúgio para o espírito da Mulher Selvagem. Um
              refúgio para mulheres maduras enquanto jovens e jovens enquanto maduras. Mulheres que precisam reencontrar
              o carinho e o acolhimento para florescer, uma estação atrás da outra.
            </p>
            <p>
              O grupo é inspirado pela Contoterapia e busca promover o desenvolvimento individual de cada participante
              através dos movimentos sistêmicos, do uso dos Florais de Bach para ancorar novos padrões energéticos e de
              comportamento e da SoulCollage® (imagens criativas da Alma) como inspiração na jornada do
              autoconhecimento.
            </p>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshop>
              <InternalWorkshopTab>
                <h3>
                  <small>Ciranda Online</small>
                  Ciranda de Mulheres
                </h3>
                <ul>
                  <li>
                    Encontros semanais online pelo zoom, me chama{' '}
                    <a
                      href={WHATSAPPLINK}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      aqui no WhatsApp
                    </a>{' '}
                    e confere os dias e horários disponíveis
                  </li>
                  <li> Sem pré-requisitos</li>
                  <li>Para toda mulher que busca seu desenvolvimento pessoal ou profissional</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse internalWorkshop-button`}
                >
                  Inscrição Workshop Online <small></small>
                </a>
              </InternalWorkshopTab>
              <InternalWorkshopTab>
                <h3>
                  <small>Ciranda Presencial</small>
                  Ciranda de Mulheres
                </h3>
                <ul>
                  <li>
                    Encontros mensais ou quinzenais, me chama{' '}
                    <a
                      href={WHATSAPPLINK}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      aqui no WhatsApp
                    </a>{' '}
                    e confere os dias e horários disponíveis
                  </li>
                  <li>Sem pré-requisitos</li>
                  <li>Para toda mulher que busca seu desenvolvimento pessoal ou profissional</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse button internalWorkshop-button`}
                >
                  Inscrição Workshop Presencial <small></small>
                </a>
              </InternalWorkshopTab>
            </InternalWorkshop>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
